import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()


function LearnMoreCTA(props) {

  const wrapperClassNames = classNames(
    'body-sections', 
    'section', 
    'learn-more-section',
    {
      "color-back": props.colorBack
    }
  )
  
  return (
    <section
      className={wrapperClassNames}
    >
      <div className="columns has-text-centered-tablet">
        <div className="column is-5"></div>
        <div className="column">
          <h3 style={{ marginBottom: "1.5rem" }}>
            {props.post.learnMore.heading}
          </h3>
        </div>
        <div className="column is-5"></div>
      </div>
      <div className="columns has-text-centered-tablet">
        <div className="column is-4"></div>
        <div className="column mobile-col">
          <MarkdownViewer markdown={props.post.learnMore.blurb} />
        </div>
        <div className="column is-4"></div>
      </div>

      <div className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <ButtonGroupMap isCentered noReverse
            buttons={props.post.learnMore.buttons}
          />
        </div>
        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default LearnMoreCTA
